@use "sass:map";
@import "_values";

/**
 * SASS COLOR PACK
 * This will disable the Vuetify color pack.
 * It saves about 30kb of CSS in the final build.
 * Reference: vuetifyjs.com/en/styles/colors/#sass-color-pack
 */
$color-pack: false;

/**
 * MATERIAL THEME COLORS
 * All the following variables are declared within 
 * Vuetify's`_light` and `_dark` settings sources.
 * Whatever is declared below over-rules default
 * Vuetify base theme (when no color props are declared).
 *
 * $material-light: ~/node_modules/vuetify/src/styles/settings/_light.scss
 * $material-dark:  ~/node_modules/vuetify/src/styles/settings/_dark.scss
 */

// -darkTheme-
$material-dark: (
  "app-bar": var(--color-control-background-theme),
  "background": var(--color-base-theme),
  "bottom-navigation": var(--color-base-background),
  "buttons": (
    "focused": var(--color-text-disabled-theme),
  ),
  "cards": var(--color-base-layer1),
  "code": (
    "background": var(--color-control-background-theme),
  ),
  "kbd": (
    "background": var(--color-overlayBackground),
  ),
  "selection-controls": (
    "disabled": var(--color-text-disabled-theme),
  ),
  "table": (
    "hover": transparent,
  ),
  "text": (
    "primary": var(--color-text-theme),
    "secondary": var(--color-text-subtle-theme),
    "link": var(--color-primary),
  ),
  "text-fields": (
    "filled": var(--color-control-background-theme),
    "filled-hover": var(--color-control-background-theme),
    "outlined": var(--color-control-stroke),
    "outlined-disabled": var(--color-control-subtle-theme),
    "outlined-hover": var(--color-border-contrast-theme),
  ),
  "input-bottom-line": var(--color-control-stroke),
);

// -lightTheme-
$material-light: (
  "app-bar": var(--color-control-background-theme) /* v-btn default background-color */,
  "background": var(--color-base-theme),
  "bottom-navigation": var(--color-base-background),
  "buttons": (
    "focused": var(--color-text-disabled-theme),
  ),
  "cards": var(--color-base-layer1),
  "code": (
    "background": var(--color-control-background-theme),
  ),
  "kbd": (
    "background": var(--color-overlayBackground),
  ),
  "selection-controls": (
    "disabled": var(--color-text-disabled-theme),
  ),
  "table": (
    "hover": transparent,
  ),
  "text": (
    "primary": var(--color-text-theme),
    "secondary": var(--color-text-subtle-theme),
    "link": var(--color-primary),
  ),
  "text-fields": (
    "filled": var(--color-control-background-theme),
    "filled-hover": var(--color-control-background-theme),
    "outlined": var(--color-control-stroke),
    "outlined-disabled": var(--color-control-subtle-theme),
    "outlined-hover": var(--color-border-contrast-theme),
  ),
  "input-bottom-line": var(--color-control-stroke),
);

/** - - - - - - - - - - - - - - - - - - - - - - -
 * Media queries breakpoints from Wx to Vuetify
 */
$grid-breakpoints: (
  "xs": 0,
  "sm": map.get($wxBreakpoint-pixelValues, "smBreakpoint"),
  "md": map.get($wxBreakpoint-pixelValues, "mdBreakpoint"),
  "lg": map.get($wxBreakpoint-pixelValues, "lgBreakpoint"),
  "xl": map.get($wxBreakpoint-pixelValues, "xlBreakpoint"),
);

/** - - - - - - - - - - - - - - - - - - - - - - -
 * VBtn
 * ~/node_modules/vuetify/src/components/VBtn/_variables.scss
 */
$btn-border-radius: var(--border-radius-form-elements);
$btn-text-transform: none;
$btn-font-weight: 700;
$btn-hover-opacity: 0.3;
$btn-focus-opacity: 0.3;
$btn-sizes: (
  "x-small": 20,
  "small": 26,
  "default": 30,
  "large": 36,
  "x-large": 42,
);

// Typography
$line-height-root: 1.25; // required to support French accents on uppercase characters

// Form controls
// v-text-filed, v-select and v-autocomplete
// (v-autocomplete is based on v-select and v-select is based on v-text-field so they all share some of the variables)
$text-field-border-radius: var(--border-radius-form-elements);
$text-field-filled-full-width-outlined-slot-min-height: 39px;
$text-field-filled-full-width-outlined-dense-slot-min-height: 29px;
$text-field-filled-full-width-outlined-single-line-slot-min-height: 29px;
$text-field-filled-margin-top: 15px;
$text-field-dense-label-top: 12px;
$text-field-dense-label-active-transform: translateY(-8px) scale(0.75);
$text-field-dense-prepend-append-margin-top: 10px;
$text-field-single-line-label-top: 6px;
$text-field-single-line-prepend-append-margin-top: 3px;
$text-field-enclosed-details-padding: 0 10px;
$select-selections-padding-top: 15px;
$autocomplete-dense-enclosed-input-margin-top: 15px;

// v-cards
$card-border-radius: var(--border-radius-lg);

// v-data-table
$data-table-border-radius: var(--border-radius-lg);
$data-table-regular-header-height: 53px;
$data-table-regular-row-height: 37px;
$data-table-regular-row-font-size: 1rem;
$data-table-regular-header-font-size: 1rem;

// v-dialog (modal)
$dialog-border-radius: var(--border-radius-lg);

// Lists
$list-dense-min-height: 32px;

// v-menu (is used by v-select to present options)
$menu-content-border-radius: var(--border-radius-form-elements);

// v-tabs
$tabs-bar-height: 32px;
$tab-font-size: 0.75rem;
$tab-font-weight: bold;
$tabs-item-letter-spacing: normal;

// v-tooltip
$tooltip-opacity: 0.93;
$tooltip-padding: var(--wx-panel-container-padding);
$tooltip-text-color: var(--color-inverted-text-theme);
$tooltip-background-color: var(--color-inverted-container-theme);
